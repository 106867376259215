import React, { useState } from "react";
import useApp from "../../../store";
import { UpdateUserData } from "../../../utils/user";
import styled from "styled-components";
import ECAInput from "../../input/ECAInput";

export default function MentorExperience({
  setLoading,
  setError,
  Loading,
  setStep,
  step,
}) {
  const { profileData, updateUser } = useApp();
  const newUniversityObj = {
    name: "",
    title: "",
    details: "",
  };

  const [workExperiences, setWorkExperiences] = useState(
    profileData?.workExperiences &&
      Array.isArray(profileData?.workExperiences) &&
      profileData?.workExperiences?.length > 0
      ? profileData.workExperiences
      : [newUniversityObj]
  );

  function handleSubmit(e) {
    e.preventDefault();
    const uid = profileData.uid;
    const stepCompletedProfile = profileData?.stepCompletedProfile;
    const data = {
      workExperiences,
      stepCompletedProfile:
        stepCompletedProfile &&
        (isNaN(stepCompletedProfile) || stepCompletedProfile < step)
          ? step
          : stepCompletedProfile,
    };
    const changeStep = () => {
      setStep(step + 1);
      updateUser({ ...profileData, ...data });
    };
    let obj = { ...profileData, ...data };
    UpdateUserData(uid, obj, setLoading, setError, changeStep, false);
  }

  console.log(workExperiences);

  return (
    <Container onSubmit={handleSubmit} className="basic-info-form">
      {workExperiences &&
        Array.isArray(workExperiences) &&
        workExperiences?.length > 0 &&
        React.Children.toArray(
          workExperiences.map((uni, index) => (
            <ECAInput
              index={index}
              single={uni}
              setValue={setWorkExperiences}
              value={workExperiences}
              obj={newUniversityObj}
            />
          ))
        )}
      <button
        disabled={!(workExperiences.length > 0) || Loading}
        type="submit"
        className="aj-btn-primary"
      >
        {Loading ? "Loading..." : "Next"}
      </button>
    </Container>
  );
}

const Container = styled.form``;
