import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { useLocation } from "react-router-dom";
import useApp from "../../store";
import { IoMdCheckmark } from "react-icons/io";
import Notification from "../../components/notifications/Notification";
import BasicInfo from "../../components/forms/mentors/BasicInfo";
import Study from "../../components/forms/mentors/Study";
import AppliedTo from "../../components/forms/mentors/AppliedTo";
import ECA from "../../components/forms/mentors/ECA";
import ServiceCharge from "../../components/forms/mentors/ServiceCharge";
import MentorExperience from "../../components/forms/mentors/MentorExperiences";
import Services from "../../components/forms/mentors/Services";

export default function ProfileDataUpdate() {
  const { profileData } = useApp();
  const [Step, setStep] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState();

  const [ShowNotification, setShowNotification] = useState(false);

  const FormStepComponents = {
    1: {
      title: "Provide Your Information",
      component: (
        <BasicInfo
          Loading={Loading}
          setLoading={setLoading}
          setError={setError}
          setStep={setStep}
          step={Step}
        />
      ),
    },
    2: {
      title: "Information about your Education.",
      component: (
        <Study
          Loading={Loading}
          setLoading={setLoading}
          setError={setError}
          setStep={setStep}
          step={Step}
        />
      ),
    },
    3: {
      title: "Universities Applied to?",
      component: (
        <AppliedTo
          Loading={Loading}
          setLoading={setLoading}
          setError={setError}
          setStep={setStep}
          step={Step}
        />
      ),
    },
    4: {
      title: "Extra Curricular Activities",
      component: (
        <ECA
          Loading={Loading}
          setLoading={setLoading}
          setError={setError}
          setStep={setStep}
          step={Step}
        />
      ),
    },
    5: {
      title: "Experiences",
      component: (
        <MentorExperience
          Loading={Loading}
          setLoading={setLoading}
          setError={setError}
          setStep={setStep}
          step={Step}
        />
      ),
    },
    6: {
      title: "How you can help the students (Services)",
      component: (
        <Services
          Loading={Loading}
          setLoading={setLoading}
          setError={setError}
          setStep={setStep}
          step={Step}
        />
      ),
    },
    7: {
      title: "How much do you wanna charge people?",
      component: (
        <ServiceCharge
          Loading={Loading}
          setLoading={setLoading}
          setError={setError}
          setStep={setStep}
          step={Step}
        />
      ),
    },
  };

  useEffect(() => {
    Error && setShowNotification(true);
  }, [Error]);

  useEffect(() => {
    if (
      profileData?.stepCompletedProfile &&
      Number(profileData?.stepCompletedProfile) &&
      Number(profileData?.stepCompletedProfile) < 7
    ) {
      setStep(Number(profileData?.stepCompletedProfile) + 1);
    }
  }, []);

  console.log(Step, profileData?.stepCompletedProfile);

  return (
    <Container>
      <div className="container">
        <div className="title">
          <div className="track">
            {React.Children.toArray(
              [...Object.keys(FormStepComponents)].map((stp) => {
                return (
                  <button
                    className={`step ${
                      profileData?.stepCompletedProfile >= Number(stp)
                        ? "comp"
                        : ""
                    }`}
                    onClick={() =>
                      profileData?.stepCompletedProfile >= Number(stp) &&
                      setStep(Number(stp))
                    }
                  >
                    <span>
                      <IoMdCheckmark />
                    </span>
                  </button>
                );
              })
            )}
          </div>
          <h1 className="aj-text-primary">
            {FormStepComponents?.[Step]?.title}
          </h1>
        </div>
        {FormStepComponents?.[Step]?.component}
        {Error && <p className="aj-error">{Error?.toString()}</p>}
      </div>
      <div className="image">
        <img src="/images/london.png" alt="am subtle background" />
      </div>
      {ShowNotification && (
        <Notification
          children={Error}
          duration={4000}
          show={ShowNotification}
          setShow={() => setShowNotification(false)}
          variant="Error"
        />
      )}
    </Container>
  );
}

const Container = styled.main`
  position: relative;
  min-height: calc(100dvh - 60px);
  display: flex;
  flex-direction: column;
  & > .container {
    padding: 20px;
    & > .title {
      padding: 24px 0 32px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      & > .track {
        width: min(500px, 100%);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > .step {
          position: relative;
          &:not(:last-child) {
            flex: 1;
            &::after {
              content: "";
              position: absolute;
              background-color: var(--aj-gray-100);
              width: 100%;
              height: 2px;
              left: 19px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &.comp {
            &::after {
              background-color: var(--aj-primary);
            }
            & > span {
              background-color: var(--aj-primary);
            }
          }
          & > span {
            width: 20px;
            aspect-ratio: 1/1;
            display: flex;
            border-radius: 50%;
            background-color: var(--aj-gray-100);
            & > * {
              margin: auto;
              color: var(--aj-white);
              font-size: 1.2rem;
            }
          }
        }
      }
      & > h1 {
        font-size: 2rem;
        text-align: center;
        width: min(350px, 100%);
        margin: 0 auto;
      }
    }
    & > form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: min(500px, 100%);
      margin: 0 auto;
      & > .two-col,
      & > .with-image > .fields > .two-col {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      & > .with-image {
        display: flex;
        gap: 1rem;
        @media (max-width: 600px) {
          flex-direction: column;
        }
        & > .profile-image {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px;
          position: relative;
          overflow: hidden;
          border: 1px solid var(--aj-gray-100);
          border-radius: 8px;
          width: min(128px, 100%);
          aspect-ratio: 1/1;
          @media (max-width: 600px) {
            width: min(300px, 100%);
            margin: 0 auto;
          }
          & > input,
          & > img {
            position: absolute;
          }
          & > input {
            z-index: 2;
            width: 100%;
            height: 100%;
            inset: 0;
            opacity: 0;
          }
          & > img {
            width: 100%;
            height: 100%;
            inset: 0;
            object-fit: cover;
            object-position: center;
          }
          & > p,
          & > .hover > p {
            text-align: center;
            color: var(--aj-gray-300);
            line-height: 100%;
            & > span {
              font-size: 0.75rem;
              padding-top: 0.5rem;
            }
          }
          & > .hover {
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            background-color: var(--aj-gray-25);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            backdrop-filter: blur(20px);
            opacity: 0;
            transform: opacity 500ms ease-in-out;
            & > p {
              color: var(--aj-gray-500);
            }
          }
          &:hover {
            & > .hover {
              opacity: 1;
            }
          }
        }
        & > * {
          &:nth-child(2) {
            flex: 1;
          }
        }
      }
      & > button {
        margin-left: auto;
      }
    }
  }
  & > .image {
    margin-top: auto;
    width: 100%;
    padding: 0;
    & > img {
      width: 100%;
      height: auto;
    }
  }
`;
