import React, { useState } from "react";
import styled from "styled-components";

export default function MentorsAppliedUniversity({
  index,
  single,
  value,
  setValue,
  obj,
}) {
  const labelN = "University Name";
  const labelA = "Got accepted?";
  const labelS = "Scholarship Info (optional)";
  const labelL = "Location";
  const [SelectOpen, setSelectOpen] = useState(false);

  const Option = ["Accepted", "Wait Listed", "Not Accepted"];

  function handleAppliedUniversity(index, key, inp) {
    let arr = [...JSON.parse(JSON.stringify(value))];
    switch (key) {
      case "name":
        arr[index].name = inp;
        break;
      case "location":
        arr[index].location = inp;
        break;
      case "accepted":
        arr[index].accepted = inp;
        break;
      case "scholarship":
        arr[index].scholarship = inp;
        break;
      default:
        break;
    }
    setValue(arr);
  }

  return (
    <Container>
      <InputItem>
        <label
          className="h4"
          htmlFor={labelN
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
        >
          {labelN}
        </label>
        <input
          type={"text"}
          name={labelN
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          id={labelN
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          required={true}
          placeholder={"University Name"}
          className={``}
          value={single?.name}
          onChange={(e) =>
            handleAppliedUniversity(index, "name", e.target.value)
          }
        />
      </InputItem>

      <InputItem>
        <label
          className="h4"
          htmlFor={labelL
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
        >
          {labelL}
        </label>
        <input
          type={"text"}
          name={labelL
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          id={labelL
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          required={true}
          placeholder={"Dhaka, Bangledesh"}
          className={``}
          value={single?.location}
          onChange={(e) =>
            handleAppliedUniversity(index, "location", e.target.value)
          }
        />
      </InputItem>

      <Toggle className={SelectOpen ? "open" : ""}>
        <label
          className="h4"
          htmlFor={labelA
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
        >
          {labelA}
        </label>
        <span>{single?.accepted}</span>
        <div className="overFlow">
          {React.Children.toArray(
            Option?.map((op) => (
              <span
                onClick={() => {
                  handleAppliedUniversity(index, "accepted", op);
                  setSelectOpen(false);
                }}
              >
                {op}
              </span>
            ))
          )}
        </div>
        <button type="button" onClick={() => setSelectOpen(true)}></button>
      </Toggle>

      <InputItem>
        <label
          className="h4"
          htmlFor={labelS
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
        >
          {labelS}
        </label>
        <textarea
          name={labelS
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          id={labelS
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          required={false}
          placeholder={`Enter your scholarship status at ${single?.name || ""}`}
          className={``}
          value={single?.scholarship}
          onChange={(e) =>
            handleAppliedUniversity(index, "scholarship", e.target.value)
          }
        ></textarea>
      </InputItem>

      <div
        className={`extra-btn delete ${
          index === value.length - 1 ? "" : "full"
        }`}
        role="button"
        onClick={() => setValue(value.filter((el, ind) => ind !== index))}
      >
        Delete {single?.name}
      </div>
      <div
        className={`extra-btn add-new ${
          index === value.length - 1 ? "" : "hide"
        }`}
        role="button"
        onClick={() => setValue([...value, obj])}
      >
        Add Another
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  @media (max-width: 670px) {
    grid-template-columns: 1fr;
  }
  & > * {
    &:nth-child(1),
    &:nth-child(4) {
      grid-column: 1 / span 2;
      @media (max-width: 670px) {
        grid-column: 1 / span 1;
      }
    }
  }
  & > .extra-btn {
    text-align: center;
    border: 1px solid var(--aj-primary);
    border-radius: 8px;
    color: var(--aj-primary);
    padding: 8px 12px;
    &.full {
      grid-column: 1 / span 2;
      @media (max-width: 670px) {
        grid-column: 1 / span 1;
      }
    }
    &.add-new {
      &.hide {
        display: none;
      }
    }
  }
`;

const InputItem = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid var(--aj-gray-100);
  border-radius: 8px;
  margin-top: 0.5lh;
  & > label {
    position: absolute;
    left: 1rem;
    top: -0.5lh;
    background-color: var(--aj-white);
    padding: 0 0.5rem;
    font-size: 1rem;
  }
  & > input {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    color: #000;
    &:invalid,
    & > .error {
      color: var(--aj-primary);
    }
  }
  & > textarea {
    width: 100%;
    height: 120px;
    padding: 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    color: #000;
    resize: none;
    &:invalid,
    & > .error {
      color: var(--aj-primary);
    }
  }
`;

const Toggle = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid var(--aj-gray-100);
  border-radius: 8px;
  margin-top: 0.5lh;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  & > label {
    position: absolute;
    top: -0.5lh;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--aj-white);
    padding: 0 0.5rem;
    font-size: 1rem;
  }
  & > .overFlow {
    display: none;
  }
  & > button {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  &.open {
    position: relative;
    & > .overFlow {
      position: absolute;
      inset: 0;
      top: 100%;
      bottom: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: var(--aj-white);
      z-index: 2;
      border: 1px solid var(--aj-gray-100);
      border-radius: 0 0 8px 8px;
      box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.5);
      & > span {
        font-size: 0.8rem;
        padding: 4px 12px;
        text-align: center;
        &:not(:last-child) {
          border-bottom: 1px solid var(--aj-gray-100);
        }
      }
    }
  }
`;
