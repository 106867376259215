import React, { useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
// import {
//   BsFileEarmarkText,
//   BsChevronRight,
//   BsArrowRight,
// } from "react-icons/bs";
// import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
// import "@splidejs/react-splide/css";

export default function OurProducts() {
  // const Filters = [
  //   {
  //     color: "green",
  //     name: "EPT Courses",
  //     totalCourses: "05",
  //   },
  //   {
  //     color: "purple",
  //     name: "Guides",
  //     totalCourses: "03",
  //   },
  //   {
  //     color: "blue",
  //     name: "SAT-GRE",
  //     totalCourses: "07",
  //   },
  // ];

  // const [Courses, setCourses] = useState([
  //   {
  //     img: "/images/hero-bg-xs.webp",
  //     title: "Course Title",
  //     instructor: "lorem Impresum",
  //     link: "",
  //   },
  //   {
  //     img: "/images/hero-bg-xs.webp",
  //     title: "Course Title",
  //     instructor: "lorem Impresum",
  //     link: "",
  //   },
  //   {
  //     img: "/images/hero-bg-xs.webp",
  //     title: "Course Title",
  //     instructor: "lorem Impresum",
  //     link: "",
  //   },
  //   {
  //     img: "/images/hero-bg-xs.webp",
  //     title: "Course Title",
  //     instructor: "lorem Impresum",
  //     link: "",
  //   },
  //   {
  //     img: "/images/hero-bg-xs.webp",
  //     title: "Course Title",
  //     instructor: "lorem Impresum",
  //     link: "",
  //   },
  // ]);

  // const [SelFilter, setSelFilter] = useState(Filters[0].color);

  return (
    <Container className="aj-container_fluid">
      <div className="content">
        <h2>Find the Right Help for Every Step</h2>
        {/* <div className="filters">
          {React.Children.toArray(
            Filters.map((item) => (
              <div
                className={`filter_card`}
                role="button"
                onClick={() => setSelFilter(item.color)}
                style={{
                  background: `var(${
                    item.color === SelFilter
                      ? "--" + item.color
                      : "--aj-transparent"
                  })`,
                  borderColor: `var(${
                    item.color === SelFilter
                      ? "--" + item.color
                      : "--aj-gray-100"
                  })`,
                }}
              >
                <div
                  className="icon"
                  style={{
                    background: `var(${
                      item.color === SelFilter
                        ? "--aj-gray-100"
                        : "--" + item.color
                    })`,
                    color: `var(${
                      item.color === SelFilter
                        ? "--" + item.color
                        : "--aj-gray-100"
                    })`,
                  }}
                >
                  <BsFileEarmarkText />
                </div>
                <div className="card_content">
                  <h5 className="aj-subtitle">{item.name}</h5>
                  <small>{item.totalCourses} courses</small>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="content_slide">
          <Splide
            hasTrack={false}
            options={{
              type: "",
              rewind: false,
              width: "100%",
              gap: "32px",
              pagination: false,
              perPage: 4,
              breakpoints: {
                900: {
                  perPage: 2,
                },
                600: {
                  perPage: 1,
                },
              },
            }}
          >
            <SplideTrack>
              {Array.isArray(Courses) &&
                Courses &&
                Courses.length > 0 &&
                React.Children.toArray(
                  Courses.map((item) => (
                    <SplideSlide>
                      <div className="card">
                        <div className="card_media">
                          <img src={item.img} alt={item.title} />
                        </div>
                        <div className="card_content">
                          <h4>{item.title}</h4>
                          <p>{item.instructor}</p>
                          <div className="card_button">
                            <a href={item.link} className="aj-btn-primary">
                              See Details <BsArrowRight />
                            </a>
                          </div>
                        </div>
                      </div>
                    </SplideSlide>
                  ))
                )}
            </SplideTrack>
            <div className="splide__arrows">
              <button className="splide__arrow splide__arrow--prev">
                <BsChevronRight />
              </button>
              <button className="splide__arrow splide__arrow--next">
                <BsChevronRight />
              </button>
            </div>
          </Splide>
        </div> */}

        <div className="ctas">
          <Link to="/our-story" className="aj-btn-primary">
            Learn More
          </Link>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.section`
  background-color: #000;
  color: var(--aj-gray-100);
  margin: 0 auto;
  & > .content {
    padding-top: 62px;
    padding-bottom: 62px;
    & > h2 {
      text-align: center;
    }
    & > .ctas {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px;
      & > a {
        display: inline;
        width: max-content;
        padding: 12px 24px;
        border-radius: 12px;
      }
    }
    & > .filters {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 56px;
      & > .filter_card {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 16px 24px;
        border: 2px solid var(--aj-gray-100);
        border-radius: 8px;
        width: min(238px, 100%);
        gap: 14px;
        cursor: pointer;
        & > .icon {
          width: 44px;
          height: 44px;
          padding: 10px;
          font-size: 24px;
          border-radius: 50%;
        }
        & > .card_content {
          & > * {
            line-height: 100%;
          }
        }
      }
    }
    & > .content_slide {
      width: 100%;
      position: relative;
      padding-top: 3rem;
      display: flex;
      justify-content: center;
      .card {
        display: flex;
        flex-direction: column;
        border: 1px solid var(--aj-gray-100);
        border-radius: 8px;
        overflow: hidden;
        & > .card_media {
          width: 100%;
          aspect-ratio: 322/248;
          overflow: hidden;
          & > img {
            width: 105%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        & > .card_content {
          padding: 24px;
          padding-bottom: 20px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          & > .card_button {
            display: flex;
            & > a {
              margin-top: auto;
              display: flex;
            }
          }
        }
      }
      .splide__pagination {
        gap: 2;
        bottom: -20px;
      }
      .splide__pagination__page {
        transform: scale(1);
      }
      .splide__pagination__page.is-active {
        background-color: var(--aj-primary);
        transform: scale(1.4);
      }
      .splide__arrow {
        font-size: 24px;
        background-color: var(--aj-gray-100);
        border: 5px solid #000;
        color: var(--aj-primary);
        opacity: 1;
        & > svg {
          fill: var(--aj-primary);
        }
        &--prev {
          left: -1.5rem;
        }
        &--next {
          right: -1.5rem;
        }
      }
    }
  }
`;
