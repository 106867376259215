import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useApp from "../../../store";
import { UpdateUserData } from "../../../utils/user";

import countryData from "../../../data/country.json";

import SimpleLoading from "../../Loaders/SimpleLoading";
import { isValidPath, isValidUrl } from "../../../utils/checkers/validURL";
import { res_sm } from "../../../settings";
import LegendInput from "../../input/LegendInput";

export default function DesiredCountry({
  setLoading,
  setError,
  Loading,
  setStep,
  step,
}) {
  const { profileData, updateUser } = useApp();
  const options = countryData;

  const [desiredCountry, setDesiredCountry] = useState(
    profileData?.desiredCountry
  );
  const [Search, setSearch] = useState();
  const [CountryData, setCountryData] = useState(options);

  function handleClick(e, value) {
    setLoading(true);
    setDesiredCountry(value);
    e.preventDefault();
    const uid = profileData.uid;
    const stepCompletedProfile = profileData?.stepCompletedProfile;
    const data = {
      desiredCountry: value,
      stepCompletedProfile:
        stepCompletedProfile &&
        (isNaN(stepCompletedProfile) || stepCompletedProfile < step)
          ? step
          : stepCompletedProfile,
    };
    const changeStep = () => {
      setStep(step + 1);
      updateUser({ ...profileData, ...data });
    };
    UpdateUserData(uid, data, setLoading, setError, changeStep);
  }

  useEffect(() => {
    if (Search) {
      const cp = JSON.parse(JSON.stringify(options));
      const dt = [...cp].filter((el) =>
        el?.name?.toLocaleLowerCase()?.includes(Search.toLocaleLowerCase())
      );
      setCountryData(dt);
    } else {
      setCountryData(options);
    }
  }, [Search]);

  return (
    <Container onSubmit={(e) => e.preventDefault()}>
      <div className="search">
        <LegendInput
          label={"Search For a Country"}
          placeholder="Denmark"
          value={Search}
          setValue={setSearch}
          required={false}
        />
      </div>
      <div className="grid">
        {CountryData &&
          Array.isArray(CountryData) &&
          CountryData.length > 0 &&
          React.Children.toArray(
            CountryData.map((option) => (
              <div
                className={`element ${
                  desiredCountry === option?.name ? "selected" : ""
                }`}
                role="button"
                tabIndex={0}
                onClick={(e) => handleClick(e, option?.name)}
              >
                {typeof option?.icon === "string" &&
                (isValidUrl(option?.icon) || isValidPath(option?.icon)) ? (
                  <img src={option?.icon} alt={`${option?.title} icon`} />
                ) : (
                  <span className="icon">{option?.icon}</span>
                )}
                <p className="aj-label2">{option?.name}</p>
              </div>
            ))
          )}
      </div>
      <SimpleLoading show={Loading} />
    </Container>
  );
}

const Container = styled.form`
  position: relative;
  & > .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    @media (max-width: ${res_sm}px) {
      grid-template-columns: repeat(2, 1fr);
    }
    & > .element {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background-color: var(--aj-gray-100);
      padding: 12px;
      border-radius: 16px;
      border: 1px solid var(--aj-gray-100);
      &.selected {
        background-color: var(--aj-white);
        border-color: var(--aj-primary);
      }
      & > * {
        text-align: center;
      }
      & > .icon {
        font-size: 3rem;
      }
      & > p {
        font-weight: 500;
        color: var(--aj-dark);
      }
    }
  }
`;
