import React from "react";
import { styled } from "styled-components";
import Summary from "../../components/Summary";
import faqData from "../../data/FAQ.json";
import { Link } from "react-router-dom";

export default function FAQ() {
  const FAQs = faqData.slice(0, 3);
  return (
    <Container className="aj-container_lg">
      <h2>Frequently Asked Questions?</h2>
      <div className="contents">
        {React.Children.toArray(
          FAQs.map((item) => (
            <Summary title={item?.question} details={item?.answer} />
          ))
        )}
        <Link to={`/faq`} className="">
          Find out more &rarr;
        </Link>
      </div>
    </Container>
  );
}
const Container = styled.section`
  & > h2 {
    text-align: center;
    margin-bottom: 56px;
  }
  & > .contents {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > a {
      width: max-content;
      margin: 12px auto;
      color: var(--aj-primary);
    }
  }
`;
