// After `yarn add firebase` Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD581QRfJS_K0nnF8jLgAU0M7eUrR_H8iA",
  authDomain: "abroadmates-web.firebaseapp.com",
  projectId: "abroadmates-web",
  storageBucket: "abroadmates-web.appspot.com",
  messagingSenderId: "1087186949830",
  appId: "1:1087186949830:web:567519924a65618bfba9c7",
  measurementId: "G-TMY0MRTC0H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
export const db = getFirestore(app);
export { app, auth };
