import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Cards({ Data, id = undefined, transfer = false }) {
  return (
    <Container className="card">
      <div className="card_media">
        <img
          src={Data?.photoURL ? Data.photoURL : "/images/hero-bg-xs.webp"}
          alt={Data.displayName}
        />
      </div>
      <div className="card_content">
        <h4>{Data.displayName}</h4>
        <p>
          {Data.currentMajor} @ {Data?.currentUniversity}
        </p>
        <div className="card_button">
          {Data?.services &&
            Array.isArray(Data.services) &&
            Data.services.length > 0 &&
            React.Children.toArray(
              [Data.services[0]].map((chip) => (
                <button className="aj-btn-territory">{chip}</button>
              ))
            )}
        </div>
      </div>
      {transfer && (
        <Link className="transfer" to={`/mate/abacas${id}s-rdfsNk`}></Link>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--aj-gray-100);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  & > .card_media {
    width: 100%;
    aspect-ratio: 322/248;
    overflow: hidden;
    & > img {
      width: 105%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  & > .card_content {
    padding: 24px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > .card_button {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      & > a {
        margin-top: auto;
        display: flex;
      }
    }
  }
  & > .transfer {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;
