import React from "react";
import styled from "styled-components";

export default function OurStory() {
  return (
    <Component>
      <h1>
        Meet AbroadMates, a 1-on-1 mentorship platform for students looking to
        study abroad with scholarships.
      </h1>
      <p>
        Hi everyone! Rahat here, Co-Founder of AbroadMates. I’d love to
        introduce you to our platform—AbroadMates—a platform to book 1on1
        sessions for Studying Abroad.
      </p>

      <p>Here’s what we offer:</p>

      <ul>
        <li>
          Connecting students with vetted mentors: Current international
          students create profiles showcasing their experiences, expertise, and
          availability. A verification process ensures their credibility.
        </li>
        <li>
          Offering tailored 1:1 mentorship: Students browse mentor profiles and
          book personalized video calls based on specific needs, choosing from
          various services like application guidance, essay review, major
          exploration, and visa advice.
        </li>
        <li>
          Promoting transparency and affordability: Mentors set their own rates,
          providing students with options to fit their budget.
        </li>
        <li>
          Building a supportive community: Beyond individual sessions, the
          platform offers curated resources, forums, and events.{" "}
        </li>
      </ul>

      <h2>Why AbroadMates?</h2>
      <p>
        I started AbroadMates after my own struggles applying to 26 universities
        worldwide. Study abroad agencies were costly and impersonal, and
        traditional mentorship programs didn’t meet my needs. I wanted to create
        something better—accessible, personalized, and affordable for everyone.
      </p>

      <h2>How does it work?</h2>
      <ul>
        <li>
          Easy Access: Pick a mentor who matches your goals, interests, and
          personality.
        </li>
        <li>
          Tailored Sessions: Get specific guidance on applications,
          scholarships, exams, or any part of your journey.
        </li>
        <li>
          Community Support: Join a growing network of students and mentors who
          are ready to help.
        </li>
      </ul>

      <h2>What’s next?</h2>
      <p>
        We’re constantly improving. We’re working on more features to connect
        students and mentors, expand resources, and create a supportive
        community.
      </p>

      <p>
        We’d love to hear your feedback. Reach out anytime to share your
        thoughts or join our growing platform!
      </p>
    </Component>
  );
}

const Component = styled.main`
  width: min(100%, 800px);
  margin: 0 auto;
  padding-bottom: 56px;
  & > * {
    margin: 2rem 0;
  }
  & > h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  & > p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  & > ul {
    list-style: disc;
    padding-left: 2rem;
    & > li {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  & > p,
  & > ul > li {
    color: var(--aj-gray-400);
  }
`;
