import React from "react";
import { styled } from "styled-components";

export default function SuccessMap() {
  return (
    <Container className="aj-container_lg">
      <h2>Your Success Map</h2>
      <div className="map">
        <img src="/images/success-map.webp" alt="" />
      </div>
    </Container>
  );
}
const Container = styled.section`
  background-image: url("/images/successMap-bg.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 15%, 0%;
  & > h2 {
    text-align: center;
  }
  & > .map {
    width: min(692px, 90%);
    aspect-ratio: 692/566;
    margin: 56px auto 0;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
`;
