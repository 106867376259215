import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useApp from "../store";
import StudentDashboard from "../views/DashboardStudent";
import AdminDashboard from "../views/DashboardAdmin";
import MentorDashboard from "../views/DashboardMentor";

export default function Dashboard() {
  const { loggedInStatus, userIs } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedInStatus) {
      navigate("/sign-in");
    }
  }, [loggedInStatus, navigate]);

  return (
    <Container>
      {
        {
          admin: <AdminDashboard />,
          mentor: <MentorDashboard />,
          requested: <MentorDashboard />,
          student: <StudentDashboard />,
        }[userIs]
      }
    </Container>
  );
}

const Container = styled.main``;
