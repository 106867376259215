import React, { useState } from "react";
import { styled } from "styled-components";

export default function Blog() {
  const [Blog, setBlog] = useState([
    {
      img: "/blog/blog-1.png",
      title: `What does "Holistic Admission" mean?`,
      details:
        "Trust me, ‘Study Abroad’ carries a lot of new vocabularies which I was never aware of. Like those ten in twenty other students when I dived into this journey, I sank. However, to save your valuable days, we; your Abroadmates, have thought of introducing these terms. We are going to start with ‘Holistic Admission’. Let’s go.",
      link: "https://open.substack.com/pub/abroadmates/p/what-does-holistic-admission-mean?r=1blsft&utm_campaign=post&utm_medium=web&showWelcomeOnShare=false",
    },
    {
      img: "/blog/blog-2.png",
      title: "HOW TO ASK FOR A LETTER OF RECOMMENDATION FROM YOUR TEACHER",
      details:
        "These pieces of paper matter, A LOT. Colleges consider these letters as some noteworthy components. Sometimes these are above Class Ranks, ECAs, Demonstrated Interests, and sometimes ESSAYS. So dependent? Yes you are, haha. Anyway, there is nothing to be afraid of. You still can contribute to this significant part of your application. Follow some simple steps and construct the LoR as you want it to be.",
      link: "https://open.substack.com/pub/abroadmates/p/how-to-ask-for-a-letter-of-recommendation?r=1blsft&utm_campaign=post&utm_medium=web&showWelcomeOnShare=false",
    },
    {
      img: "/blog/blog-3.png",
      title:
        "Why is it important to start planning 2 years prior to studying overseas?",
      details:
        "Ask any seniors, they’ve all had this thought, “I could’ve started these procedures much earlier… That’d make my overseas education application so much easier!”. So, this blog is especially for you so you can avoid this thought and apply it more efficiently!",
      link: "https://open.substack.com/pub/abroadmates/p/why-is-it-important-to-start-planning?r=1blsft&utm_campaign=post&utm_medium=web&showWelcomeOnShare=false",
    },
  ]);

  return (
    <Container className="aj-container_lg">
      <h2>Most Read Blogs</h2>
      <div className="contents">
        {Array.isArray(Blog) &&
          Blog &&
          Blog.length > 0 &&
          React.Children.toArray(
            Blog.map((item) => (
              <div className="card">
                <div className="card_media">
                  <img src={item.img} alt={item.title} />
                </div>
                <div className="card_content">
                  <h5 className="aj-subtitle">
                    {item.title.substring(0, 30)}...
                  </h5>
                  <p>{item.details.substring(0, 180)}...</p>
                  <div className="card_button">
                    <a href={item.link} className="aj-btn-secondary-outline">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))
          )}
      </div>
      <div className="buttons">
        <a
          href="https://abroadmates.substack.com"
          className="aj-btn-primary-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          See All Blogs
        </a>
      </div>
    </Container>
  );
}
const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 56px;
  & > h2 {
    text-align: center;
  }
  & > .contents {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
    gap: 16px;
    & > .card {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--aj-gray-100);
      border-radius: 8px;
      overflow: hidden;
      width: clamp(300px, 33%, 389px);
      min-width: 250px;
      & > .card_media {
        width: 100%;
        aspect-ratio: 389/224;
        overflow: hidden;
        & > img {
          width: 105%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      & > .card_content {
        padding: 24px 16px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        & > .card_button {
          display: flex;
          & > a {
            margin-top: auto;
            display: flex;
          }
        }
      }
    }
  }
  & > .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    & > * {
      padding: 20px 24px;
    }
  }
`;
