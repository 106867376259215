import React from "react";
import styled from "styled-components";
import { FiCheckCircle, FiCircle } from "react-icons/fi";

export default function CheckBox({ value, setValue, label, align = "left" }) {
  return (
    <Container
      className={`${align ? align : ""}`}
      role="button"
      tabIndex={0}
      onClick={() => setValue(!value)}
      onKeyUp={(e) => {
        e.preventDefault();
        (e.key === " " || e.code === "Space" || e.keyCode === 32) &&
          setValue(!value);
      }}
    >
      {value ? (
        <FiCheckCircle className="icon" />
      ) : (
        <FiCircle className="icon" />
      )}{" "}
      {label}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding-left: 0.8rem;
  cursor: pointer;
  color: var(--aj-dark);
  &.inherit {
    text-align: inherit;
  }
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  & > .icon,
  & > a {
    color: var(--aj-primary);
  }
  &:focus-visible {
    box-shadow: 0 0 5px 2px var(--aj-primary);
  }
`;
