import React from "react";
import { FaPlay } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { res_sm } from "../../settings";
import useApp from "../../store";

export default function Dashboard() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { profileData } = useApp();
  const searchParams = new URLSearchParams(search);

  return (
    <Container className="">
      <section className="aj-book-session">
        <div className="aj-book-session-content">
          <p>MentorShip</p>
          <h2>
            Start Getting Students <br />
            Passionate students from all over the world!
          </h2>
          <button onClick={() => navigate("/mentors/")}>
            Update Availability <FaPlay />
          </button>
        </div>
        <div className="graphics"></div>
      </section>
      {/* {profileData?.stepCompletedProfile &&
      profileData?.stepCompletedProfile === 7 ? (
        false
      ) : (
      )} */}
      <section className="aj-profileComplete">
        <div className="aj-profileComplete-content">
          <h2>Complete Your Profile</h2>
          <p>Update your Information to start accepting requests</p>
          <button
            onClick={() => {
              searchParams.set("tab", "documents");
              navigate(`/dashboard/?${searchParams.toString()}`);
            }}
          >
            {profileData?.stepCompletedProfile &&
            profileData?.stepCompletedProfile === 7
              ? "Edit Information"
              : "Complete Now"}
          </button>
        </div>
        <div className="graphics">
          <div className="indication">
            <h2>
              {profileData?.stepCompletedProfile
                ? Math.round((profileData.stepCompletedProfile / 7) * 100)
                : "0"}
              %
            </h2>
          </div>
        </div>
      </section>
    </Container>
  );
}
const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  @media (max-width: ${res_sm}px) {
  }
  & > .aj-book-session {
    width: 100%;
    background-color: var(--aj-primary);
    color: var(--aj-white-100);
    padding: 8px 20px;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    & > .aj-book-session-content {
      & > p {
        font-size: 0.86rem;
        margin: 8px 0;
      }
      & > h2 {
        font-size: 1.72rem;
        font-weight: 600;
        margin: 8px 0;
      }
      & > button {
        background-color: var(--aj-dark);
        color: var(--aj-white);
        padding: 12px 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 24px;
        margin: 8px 0;
      }
    }
    & > .graphics {
      background-image: url("/dashboard/stars.png");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  & > .aj-profileComplete {
    width: 100%;
    background-color: var(--aj-primary);
    color: var(--aj-white-100);
    padding: 16px 20px;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    & > .aj-profileComplete-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      margin-right: auto;
      & > h2 {
      }
      & > button {
        background-color: var(--aj-white-100);
        border-radius: 8px;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 12px 24px;
      }
    }
    & > .graphics {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > .indication {
        width: min(170px, 100%);
        aspect-ratio: 1/1;
        background-color: var(--aj-white-100);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--aj-primary);
      }
    }
  }
`;
