import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./layout/Header";
import Home from "./pages/Home";
import Footer from "./layout/Footer";
import Register from "./pages/Register";
import Login from "./pages/Login";
// import MentorRegister from "./pages/MentorRegister";
import useApp from "./store";
import BrandLoading from "./components/BrandLoading";
import Dashboard from "./pages/Dashboard";
import MentorRegister from "./pages/MentorRegister";
import MentorsGridPage from "./pages/MentorsGridPage";
import SingleMentorPage from "./pages/SingleMentorPage";
import FAQ from "./pages/FAQ";
import OurStory from "./pages/OurStory";

export default function App() {
  const { userLogin, lastUpdated } = useApp();
  const location = useLocation();

  const [Loading, setLoading] = useState(false);

  const routes = [
    {
      href: "/",
      component: <Home />,
      header: true,
      footer: true,
    },
    {
      href: "/faq",
      component: <FAQ />,
      header: true,
      footer: true,
    },
    {
      href: "/mates",
      component: <MentorsGridPage />,
      header: true,
      footer: true,
    },
    {
      href: "/mate/:mentor",
      component: <SingleMentorPage />,
      header: true,
      footer: true,
    },
    {
      href: "/sign-in",
      component: <Login />,
      header: true,
      footer: true,
    },
    {
      href: "/sign-up",
      component: <Register />,
      header: true,
      footer: true,
    },
    {
      href: "/sign-up/mentor",
      component: <MentorRegister />,
      header: true,
      footer: true,
    },
    {
      href: "/dashboard",
      component: <Dashboard />,
      header: false,
      footer: false,
    },
    {
      href: "/our-story",
      component: <OurStory />,
      header: true,
      footer: true,
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (sessionStorage.loggedIn === "yes") {
      if (
        sessionStorage.user &&
        sessionStorage.user !== null &&
        sessionStorage.user !== undefined &&
        sessionStorage.user !== ""
      ) {
        let data = JSON.parse(sessionStorage.user);
        if (typeof data === "object") {
          userLogin(true, data, data.userType);
        }
      }
    }
    setLoading(false);
  }, [lastUpdated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Container>
        <Routes>
          {routes &&
            React.Children.toArray(
              routes.map((route) => (
                <Route
                  path={route?.href}
                  element={
                    <>
                      {route?.header ? <Header /> : false}
                      {route?.component}
                      {route?.footer ? <Footer /> : false}
                    </>
                  }
                />
              ))
            )}
        </Routes>
      </Container>
      <BrandLoading loading={Loading} />
    </>
  );
}
const Container = styled.div``;
