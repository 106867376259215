import React, { useState } from "react";
import styled from "styled-components";
import { sendVerificationEmail } from "../../utils/user";

export default function VerifyEmail() {
  const [Sent, setSent] = useState(false);

  return (
    <Container>
      <div className="container">
        <div className="aj-book-session-content">
          <p>Email Verification</p>
          <h2>
            Your email is not verified.
            <br />
            Please verify your mail or you may lose access.
          </h2>
          <button
            onClick={(e) => sendVerificationEmail(e, Sent, setSent)}
            disabled={Sent}
          >
            {Sent ? "Email Send" : "Verify Now"}
          </button>
        </div>
        <div className="graphics"></div>
      </div>
    </Container>
  );
}

const Container = styled.section`
  padding: 20px;
  & > .container {
    width: 100%;
    background-color: var(--aj-primary);
    color: var(--aj-white-100);
    padding: 8px 20px;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    & > .aj-book-session-content {
      & > p {
        font-size: 0.86rem;
        margin: 8px 0;
      }
      & > h2 {
        font-size: 1.72rem;
        font-weight: 600;
        margin: 8px 0;
      }
      & > button {
        background-color: var(--aj-dark);
        color: var(--aj-white);
        padding: 12px 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 24px;
        margin: 8px 0;
      }
    }
    & > .graphics {
      background-image: url("/dashboard/stars.png");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
`;
