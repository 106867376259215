import React, { useState } from "react";
import useApp from "../../../store";
import { UpdateUserData } from "../../../utils/user";
import LegendInput from "../../input/LegendInput";
import styled from "styled-components";

export default function Study({
  setLoading,
  setError,
  Loading,
  setStep,
  step,
}) {
  const { profileData, updateUser } = useApp();

  const [currentUniversity, setCurrentUniversity] = useState(
    profileData?.currentUniversity
  );
  const [currentSemester, setCurrentSemester] = useState(
    profileData?.currentSemester
  );
  const [currentMajor, setCurrentMajor] = useState(profileData?.currentMajor);
  const [IELTSScore, setIELTSScore] = useState(profileData?.IELTSScore);
  const [SATScore, setSATScore] = useState(profileData?.SATScore);

  function handleSubmit(e) {
    e.preventDefault();
    const uid = profileData.uid;
    const stepCompletedProfile = profileData?.stepCompletedProfile;
    const data = {
      currentUniversity,
      currentSemester,
      currentMajor,
      IELTSScore,
      SATScore,
      stepCompletedProfile:
        stepCompletedProfile &&
        (isNaN(stepCompletedProfile) || stepCompletedProfile < step)
          ? step
          : stepCompletedProfile,
    };
    const changeStep = () => {
      setStep(step + 1);
      updateUser({ ...profileData, ...data });
    };
    let obj = { ...profileData, ...data };
    UpdateUserData(uid, obj, setLoading, setError, changeStep);
  }

  return (
    <Container onSubmit={handleSubmit} className="basic-info-form">
      <LegendInput
        label={"University"}
        placeholder="Your current university"
        value={currentUniversity}
        setValue={setCurrentUniversity}
      />
      <LegendInput
        label={"Semester/Year"}
        placeholder="Your current Semester/Year"
        value={currentSemester}
        setValue={setCurrentSemester}
      />
      <LegendInput
        label={"Major"}
        placeholder="Your study major"
        value={currentMajor}
        setValue={setCurrentMajor}
      />
      <LegendInput
        label={"IELTS Score:"}
        placeholder="Optional"
        value={IELTSScore}
        setValue={setIELTSScore}
        required={false}
      />
      <LegendInput
        label={"SAT Score:"}
        placeholder="Optional"
        value={SATScore}
        setValue={setSATScore}
        required={false}
      />
      <button
        disabled={
          !(currentUniversity && currentSemester && currentMajor) || Loading
        }
        type="submit"
        className="aj-btn-primary"
      >
        {Loading ? "Loading..." : "Next"}
      </button>
    </Container>
  );
}

const Container = styled.form`
  & > .uni-applied {
  }
`;
