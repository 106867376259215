export const HowItWorks = [
  {
    image: "/images/search.png",
    title: "Find an expert",
    subtitle:
      "Discover and choose from our list of the world's most in-demand experts",
  },
  {
    image: "/images/book.png",
    title: "Book a video call",
    subtitle:
      "Select a time that works for both you and your expert's schedule",
  },
  {
    image: "/images/meet.png",
    title: "Virtual consultation",
    subtitle:
      "Join the 1-on-1 video call, ask questions, and get expert advice",
  },
];
