import React from "react";
import styled from "styled-components";
import faqData from "../data/FAQ.json";
import Summary from "../components/Summary";

export default function FAQ() {
  const FAQs = faqData;
  return (
    <Container className="aj-container_lg">
      <h2>Frequently Asked Questions?</h2>
      <div className="contents">
        {React.Children.toArray(
          FAQs.map((item) => (
            <Summary title={item?.question} details={item?.answer} />
          ))
        )}
      </div>
    </Container>
  );
}

const Container = styled.main`
  & > h2 {
    text-align: center;
    margin-bottom: 56px;
  }
  & > .contents {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > a {
      width: max-content;
      margin: 12px auto;
    }
  }
`;
