import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

export default function Hero() {
  return (
    <Container className="aj-container_lg">
      <div className="content">
        <h2>
          <span className="line1 large-font">1:1 Mentorship</span>For Your Study
          Abroad Journey
        </h2>
        <p>
          Connect with mentors top universities around the world, clear
          confusions, and move forward with clarity to your study abroad goals.
        </p>
        <Link to="/mates" className="aj-btn-primary">
          Find My Mentor
        </Link>
      </div>
      <div className="hero_image"></div>
    </Container>
  );
}
const Container = styled.section`
  margin: 0 auto;
  background-image: url("/images/hero-bg-lg.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: clamp(716px, 100dvh, 760px);
  @media (max-width: 780px) {
    background-size: 80%;
    background-position: bottom right;
  }
  @media (max-width: 580px) {
    text-align: center;
    background-position: bottom right;
    background-image: url("/images/hero-bg-xs.webp");
  }
  & > .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    & > h2 {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 9rem;
      width: min(580px, 100%);

      @media (max-width: 780px) {
        padding-top: 3rem;
      }
      & > span {
        color: var(--aj-primary);
      }
    }
    & > p {
      width: min(100%, 500px);
      /* padding-top: 2rem; */
      color: var(--aj-gray-400);

      @media (max-width: 780px) {
        padding-top: 0rem;
      }
    }
    & > a {
      display: inline;
      width: max-content;
      padding: 12px 24px;
      border-radius: 12px;
    }
  }
  & > .hero_image {
    @media (max-width: 580px) {
      width: min(100%319px);
      aspect-ratio: 319/301;
      margin-top: 2rem;
    }
  }
`;
