import React, { useEffect, useState } from "react";
import useApp from "../../../store";
import { UpdateUserData } from "../../../utils/user";
import LegendInput from "../../input/LegendInput";
import { handleImageUpload } from "../../../utils/uploader/photoUpload";
import LegendTextarea from "../../input/LegendTextarea";

export default function BasicInfo({
  setLoading,
  setError,
  Loading,
  setStep,
  step,
}) {
  const { profileData, updateUser } = useApp();

  // not related to form field start
  const [Uploading, setUploading] = useState(false);
  const [ImageError, setImageError] = useState(false);
  // not related to form field end

  const [photoURL, setPhotoURL] = useState(profileData?.photoURL);
  const [FirstName, setFirstName] = useState(profileData?.firstName);
  const [LastName, setLastName] = useState(profileData?.lastName);
  const [Email, setEmail] = useState(profileData?.email);
  const [Address, setAddress] = useState(profileData?.address);
  const [City, setCity] = useState(profileData?.city);
  const [BookingURL, setBookingURL] = useState(profileData?.bookingURL);
  const [BirthDay, setBirthDay] = useState(profileData?.birthday);
  const [Nationality, setNationality] = useState(profileData?.nationality);
  const [about, setAbout] = useState(profileData?.about);

  function handleSubmit(e) {
    e.preventDefault();
    const uid = profileData.uid;
    const stepCompletedProfile = profileData?.stepCompletedProfile;
    const data = {
      photoURL,
      firstName: FirstName,
      lastName: LastName,
      email: Email,
      address: Address,
      city: City,
      birthday: BirthDay,
      nationality: Nationality,
      bookingURL: BookingURL,
      about: about,
    };
    if (
      (stepCompletedProfile &&
        (isNaN(stepCompletedProfile) || stepCompletedProfile < step)) === 0
    )
      data.stepCompletedProfile = step;
    else data.stepCompletedProfile = stepCompletedProfile;
    const changeStep = () => {
      setStep(step + 1);
      updateUser({ ...profileData, ...data });
    };
    console.log(profileData);

    UpdateUserData(uid, data, setLoading, setError, changeStep);
  }

  useEffect(() => {
    if (photoURL && !Uploading && !ImageError) {
      updateUser({ ...profileData, photoURL });
      UpdateUserData(
        profileData?.uid,
        { photoURL },
        setUploading,
        setImageError,
        () => {}
      );
    }
  }, [photoURL, Uploading, ImageError, profileData?.uid]);

  return (
    <form onSubmit={handleSubmit} className="basic-info-form">
      <div className="with-image">
        <div className="profile-image">
          <input
            type="file"
            name="prodPictures"
            id="prodPictures"
            accept="image/*"
            onChange={(e) =>
              handleImageUpload(
                e,
                profileData?.uid,
                setPhotoURL,
                setUploading,
                setImageError
              )
            }
          />
          {photoURL ? (
            <>
              <img src={photoURL} alt={`${FirstName} ${LastName} profile`} />
              <div className="hover">
                <p>
                  Choose a photo
                  <br />
                  <span>smaller than 1MB</span>
                </p>
              </div>
            </>
          ) : (
            <p>
              Choose a photo
              <br />
              <span>smaller than 1MB</span>
            </p>
          )}
        </div>
        <div className="fields">
          <div className="two-col">
            <LegendInput
              label={"First Name"}
              placeholder="John"
              value={FirstName}
              setValue={setFirstName}
            />
            <LegendInput
              label={"Last Name"}
              placeholder="Doe"
              value={LastName}
              setValue={setLastName}
            />
          </div>
          <LegendInput
            label={"Email"}
            placeholder="example@example.com"
            type="email"
            value={Email}
            setValue={setEmail}
          />
        </div>
      </div>
      <LegendInput
        label={"Current Address"}
        placeholder="Current Address"
        value={Address}
        setValue={setAddress}
      />
      <LegendInput
        label={"Current City"}
        placeholder="City you are studying in"
        value={City}
        setValue={setCity}
      />
      <LegendInput
        label={"Birthday"}
        placeholder="DD / MM / YYYY"
        value={BirthDay}
        setValue={setBirthDay}
      />
      <LegendInput
        label={"Nationality"}
        placeholder="Your Origin"
        value={Nationality}
        setValue={setNationality}
      />
      <LegendInput
        label={"Calender Link"}
        placeholder="https://calendly.com/xyz"
        value={BookingURL}
        setValue={setBookingURL}
        type="url"
      />
      <LegendTextarea
        label={"About You"}
        placeholder="Tell us about how you are as a person (interesting fact)"
        value={about}
        setValue={setAbout}
      />
      <button
        disabled={!(photoURL && FirstName && LastName && Email) || Loading}
        type="submit"
        className="aj-btn-primary"
      >
        {Loading ? "Loading..." : "Next"}
      </button>
    </form>
  );
}
