import React from "react";
import Hero from "../views/Home/Hero";
import HowHelp from "../views/Home/HowHelp";
import SuccessMap from "../views/Home/SuccessMap";
import OurProducts from "../views/Home/OurProducts";
import AbroadMates from "../views/Home/AbroadMates";
import Trust from "../views/Home/Trust";
import Testimonial from "../views/Home/Testimonial";
import Blog from "../views/Home/Blog";
import FAQ from "../views/Home/FAQ";

export default function Home() {
  return (
    <main>
      <Hero />
      <HowHelp />
      <AbroadMates />
      {/* <SuccessMap />
      <Trust /> */}
      <Testimonial />
      <Blog />
      <OurProducts />
      <FAQ />
    </main>
  );
}
