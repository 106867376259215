import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CheckBox from "../components/input/CheckBox";
import { RegisterUser } from "../utils/user/userUpdates";
import useApp from "../store";
import { useNavigate } from "react-router-dom";
import BrandLoading from "../components/BrandLoading";
import BottomGraphics from "../components/BottomGraphics";

export default function Register() {
  const { userLogin, loggedInStatus } = useApp();
  const navigate = useNavigate();

  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [Email, setEmail] = useState();
  const [Pass, setPass] = useState();
  const [RePass, setRePass] = useState();
  const [TAndC, setTAndC] = useState(false);

  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState();

  useEffect(() => {
    // const user = auth.currentUser;
    if (loggedInStatus) {
      navigate("/dashboard");
    }
  }, [loggedInStatus]);

  function handleSubmit(e) {
    RegisterUser(
      e,
      FirstName,
      LastName,
      Email,
      Pass,
      RePass,
      setLoading,
      setError,
      userLogin,
      navigate
    );
  }

  console.log(Error);

  return (
    <>
      <Container className="aj-container_fluid">
        <div className="form">
          <h1 className="aj-h2">Welcome</h1>
          <p>Your first step to your dream school</p>

          <button className="page" onClick={() => navigate("/sign-in")}>
            Already Have an account?
          </button>

          <form onSubmit={handleSubmit}>
            <div className="two-col">
              <div className="element">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Your First Name"
                  value={FirstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="element">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Your Last Name"
                  value={LastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="element">
              <label className="h4" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="element">
              <label className="h4" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                name="password"
                placeholder="**********"
                id="password"
                required
                onChange={(e) => setPass(e.target.value)}
              />
            </div>

            <div className="element">
              <label className="h4" htmlFor="reenter-password">
                Re-Enter Your Password
              </label>
              <input
                type="password"
                name="reenter-password"
                id="reenter-password"
                required
                placeholder="**********"
                className={Pass !== RePass ? "error" : ""}
                onChange={(e) => setRePass(e.target.value)}
              />
            </div>

            <CheckBox
              label={
                <>
                  I agree with the Abroad Mates{" "}
                  <a href="/terms-and-conditions">Terms & Conditions</a>
                </>
              }
              value={TAndC}
              setValue={setTAndC}
            />

            {Error && (
              <p className="error">
                Error occurred:
                {Error.message}
              </p>
            )}
            {Pass !== RePass && <p className="error">Password didn't match.</p>}
            {Pass && Pass.length < 8 && (
              <p className="error">Password length at least 8 required.</p>
            )}
            <button
              disabled={
                !(
                  FirstName &&
                  LastName &&
                  Email &&
                  Pass &&
                  Pass.length >= 8 &&
                  RePass &&
                  Pass === RePass &&
                  TAndC
                )
              }
              type="submit"
              className="aj-btn-primary"
            >
              Register
            </button>
          </form>
        </div>
      </Container>
      <BottomGraphics />
      <BrandLoading loading={Loading} />
    </>
  );
}

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  min-height: 100dvh;
  & > .form {
    width: min(600px, 100%);
    padding: 64px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    gap: 2rem;
    & > button.page {
      font-size: 1rem;
      font-weight: 600;
      color: var(--aj-purple-800);
    }
    & > form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > .two-col {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      & > .element,
      & > .two-col > .element {
        position: relative;
        width: 100%;
        border: 1px solid var(--aj-gray-100);
        border-radius: 8px;
        margin-top: 0.5lh;
        & > label {
          position: absolute;
          left: 1rem;
          top: -0.5lh;
          background-color: var(--aj-white);
          padding: 0 0.5rem;
          font-size: 1rem;
        }
        & > input {
          width: 100%;
          padding: 16px;
          border: none;
          outline: none;
          color: #000;
          border-radius: 8px;
          &:invalid,
          & > .error {
            color: var(--aj-primary);
          }
        }
      }
      & > .error {
        font-size: 0.8rem;
        color: var(--aj-primary);
      }
      & > button {
        border-radius: 8px;
        font-size: 140%;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 16px;
        cursor: pointer;
        &:disabled {
          opacity: 0.8;
          filter: grayscale(30%);
        }
      }
    }
  }
  & > .image {
    width: 100%;
    padding: 0 24px;
    & > img {
      width: 100%;
      height: auto;
    }
  }
`;
