import React, { useState } from "react";
import styled from "styled-components";
import useApp from "../../../store";
import SimpleLoading from "../../Loaders/SimpleLoading";
import { UpdateUserData } from "../../../utils/user";

import { BiSolidLeaf } from "react-icons/bi";
import { FaGlobeEurope, FaPaintBrush, FaReact } from "react-icons/fa";
import { FaComputer } from "react-icons/fa6";
import { GiSailboat } from "react-icons/gi";
import { ImBook, ImBooks } from "react-icons/im";
import { LuBrainCircuit } from "react-icons/lu";
import { MdBusinessCenter } from "react-icons/md";
import { TbMicroscope } from "react-icons/tb";
import { VscLaw } from "react-icons/vsc";

export default function FieldOfStudy({
  setLoading,
  setError,
  Loading,
  setStep,
  step,
}) {
  const { profileData, updateUser } = useApp();

  const [desiredSubject, setDesiredSubject] = useState(
    profileData?.desiredSubject
  );

  const options = [
    {
      icon: <BiSolidLeaf />,
      title: "Agriculture & Forestry",
      value: "Agriculture & Forestry",
    },
    {
      icon: <FaReact />,
      title: "Applied Sciences & Professions",
      value: "Applied Sciences & Professions",
    },
    {
      icon: <FaPaintBrush />,
      title: "Arts, Design & Architecture",
      value: "Arts, Design & Architecture",
    },
    {
      icon: <MdBusinessCenter />,
      title: "Business Management",
      value: "Business Management",
    },
    {
      icon: <FaGlobeEurope />,
      title: "Environmental & Earth Science",
      value: "Environmental & Earth Science",
    },
    {
      icon: <TbMicroscope />,
      title: "Natural Sciences & Mathematics",
      value: "Natural Sciences & Mathematics",
    },
    {
      icon: <ImBook />,
      title: "Education & Training",
      value: "Education & Training",
    },
    {
      icon: <LuBrainCircuit />,
      title: "Engineering & Technology",
      value: "Engineering & Technology",
    },
    {
      icon: <GiSailboat />,
      title: "Hospitality, Tourism & Sports",
      value: "Hospitality, Tourism & Sports",
    },
    {
      icon: <ImBooks />,
      title: "Humanities",
      value: "Humanities",
    },
    {
      icon: <FaComputer />,
      title: "Computer Science & IT",
      value: "Computer Science & IT",
    },
    {
      icon: <VscLaw />,
      title: "Law",
      value: "Law",
    },
  ];

  function handleClick(e, value) {
    setLoading(true);
    setDesiredSubject(value);
    e.preventDefault();
    const uid = profileData.uid;
    const stepCompletedProfile = profileData?.stepCompletedProfile;
    const data = {
      desiredSubject: value,
      stepCompletedProfile:
        stepCompletedProfile &&
        (isNaN(stepCompletedProfile) || stepCompletedProfile < step)
          ? step
          : stepCompletedProfile,
    };
    const changeStep = () => {
      setStep(step + 1);
      updateUser({ ...profileData, ...data });
    };
    UpdateUserData(uid, data, setLoading, setError, changeStep);
  }

  return (
    <Container onSubmit={(e) => e.preventDefault()}>
      <div className="container">
        {React.Children.toArray(
          options.map((option) => (
            <div
              className={`element ${
                desiredSubject === option?.value ? "selected" : ""
              }`}
              role="button"
              tabIndex={0}
              onClick={(e) => handleClick(e, option?.value)}
            >
              {typeof option?.icon === "string" ? (
                <img src={option?.icon} alt={`${option?.title} icon`} />
              ) : (
                <span className="icon">{option?.icon}</span>
              )}
              <h2 className="">{option?.title}</h2>
            </div>
          ))
        )}
      </div>
      <SimpleLoading show={Loading} />
    </Container>
  );
}

const Container = styled.form`
  position: relative;
  & > .container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    & > .element {
      width: min(155px, 100%);
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 8px;
      padding: 12px;
      border-radius: 16px;
      border: 1px solid transparent;
      &.selected {
        border-color: var(--aj-primary);
      }
      & > * {
        text-align: center;
      }
      & > .icon {
        font-size: 2rem;
      }
      & > h2 {
        font-size: 0.8rem;
        color: var(--aj-dark);
      }
    }
  }
`;
