import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { BsChevronRight } from "react-icons/bs";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Cards from "../../components/Cards";
import { Link } from "react-router-dom";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase";

export default function AbroadMates() {
  const [Mates, setMates] = useState();
  const [Loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    try {
      async function dataCallSeries() {
        let data = [];
        const q = query(
          collection(db, "users"),
          where("userType", "==", "mentor"),
          limit(4)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });
        return data;
      }

      Promise.resolve(dataCallSeries()).then((value) => {
        setMates(value);
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Container className="aj-container_lg">
      <h2>Your AbroadMates</h2>
      <p>Real Mentors, Real Result</p>

      <div className="content_slide">
        <Splide
          hasTrack={false}
          options={{
            type: "",
            rewind: false,
            width: "100%",
            gap: "16px",
            pagination: false,
            perPage: 4,
            breakpoints: {
              900: {
                perPage: 2,
              },
              600: {
                perPage: 1,
              },
            },
          }}
        >
          <SplideTrack>
            {Array.isArray(Mates) &&
              Mates &&
              Mates.length > 0 &&
              React.Children.toArray(
                Mates.map((item) => (
                  <SplideSlide>
                    <Cards Data={item} id={item?.id} transfer={true} />
                  </SplideSlide>
                ))
              )}
          </SplideTrack>
          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
              <BsChevronRight />
            </button>
            <button className="splide__arrow splide__arrow--next">
              <BsChevronRight />
            </button>
          </div>
        </Splide>
      </div>
      <div className="buttons">
        <Link to="/mates" className="aj-btn-primary">
          Find My Mentor
        </Link>
      </div>
    </Container>
  );
}
const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > h2 {
    text-align: center;
  }
  & > p {
    text-align: center;
    font-size: 1.5rem;
  }
  & > .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    & > * {
      padding: 16px 24px;
      border-radius: 16px;
    }
  }
  & > .content_slide {
    width: 100%;
    position: relative;
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    .card {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--aj-gray-100);
      border-radius: 8px;
      overflow: hidden;
      height: 100%;
      & > .card_media {
        width: 100%;
        aspect-ratio: 322/248;
        overflow: hidden;
        & > img {
          width: 105%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      & > .card_content {
        padding: 24px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        & > .card_button {
          display: flex;
          gap: 16px;
          flex-wrap: wrap;
          & > a {
            margin-top: auto;
            display: flex;
          }
        }
      }
    }
    .splide__pagination {
      gap: 2;
      bottom: -20px;
    }
    .splide__pagination__page {
      transform: scale(1);
    }
    .splide__pagination__page.is-active {
      background-color: var(--aj-primary);
      transform: scale(1.4);
    }
    .splide__arrow {
      font-size: 24px;
      background-color: #fff;
      border: 5px solid var(--aj-gray-100);
      color: var(--aj-primary);
      opacity: 1;
      & > svg {
        fill: var(--aj-primary);
      }
      &--prev {
        left: -1.5rem;
      }
      &--next {
        right: -1.5rem;
      }
    }
  }
`;
