import React from "react";
import { styled } from "styled-components";
import { BsChevronRight } from "react-icons/bs";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

export default function Trust() {
  const chips = [
    {
      icon: "/icons/lower-costing.png",
      title: "Affordable Rates",
      text: "—no hidden fees, no fluff.",
      color: "--aj-orange-shade",
    },
    {
      icon: "/icons/quality-presentation.png",
      title: "Verified Mentors",
      text: "—with proven track records.",
      color: "--aj-purple-shade",
    },
    {
      icon: "/icons/best-content.png",
      title: "Best Support",
      text: "—created for the students.",
      color: "--aj-green-shade",
    },
    {
      icon: "/icons/easy-learning.png",
      title: "Easy Learning",
      text: "—created by Students.",
      color: "--aj-blue-shade",
    },
  ];

  const Records = [
    {
      text: "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis. Aliquam ultrices eget dolor urna condimentum. Amet purus blandit nulla sed diam auctor viverra.",
      name: "Christopher Gavigan",
      position: "Founder, X Media.com",
      avatar: "/icons/info.png",
      iframe: "https://www.youtube.com/embed/u31qwQUeGuM?si=aUI0Xvq0jinMtpGb",
    },
    {
      text: "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis. Aliquam ultrices eget dolor urna condimentum. Amet purus blandit nulla sed diam auctor viverra.",
      name: "Christopher Gavigan",
      position: "Founder, X Media.com",
      avatar: "/icons/info.png",
      iframe: "https://www.youtube.com/embed/u31qwQUeGuM?si=aUI0Xvq0jinMtpGb",
    },
    {
      text: "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis. Aliquam ultrices eget dolor urna condimentum. Amet purus blandit nulla sed diam auctor viverra.",
      name: "Christopher Gavigan",
      position: "Founder, X Media.com",
      avatar: "/icons/info.png",
      iframe: "https://www.youtube.com/embed/u31qwQUeGuM?si=aUI0Xvq0jinMtpGb",
    },
  ];
  //   "/icons/quote.png"
  return (
    <Container className="aj-container_lg">
      <div className="title">
        <h2>Transparent, Trusted, Tailored</h2>
        <p>
          Ready to take your education to the next level? Consider studying
          abroad! Discover the world and gain valuable international experience
          in a new culture!
        </p>
      </div>
      <div className="chips">
        {React.Children.toArray(
          chips.map((chip) => (
            <div
              className="chip"
              style={{ backgroundColor: `var(${chip.color})` }}
            >
              <div className="img">
                <img src={chip.icon} alt={chip.title} />
              </div>
              <div className="content">
                <h5 className="aj-subtitle">{chip.title}</h5>
                <p>{chip.text}</p>
              </div>
            </div>
          ))
        )}
      </div>
      {/* <div className="content_slide">
        <Splide
          hasTrack={false}
          options={{
            type: "loop",
            rewind: true,
            width: "100%",
            gap: "16px",
            perPage: 1,
          }}
        >
          <SplideTrack>
            {Array.isArray(Records) &&
              Records &&
              Records.length > 0 &&
              React.Children.toArray(
                Records.map((item) => (
                  <SplideSlide>
                    <div className="card">
                      <div className="card_media">
                        <iframe
                          src={item.iframe}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
                      <div className="card_content">
                        <div className="ico">
                          <img src="/icons/quote.png" alt="quote" />
                        </div>
                        <p>{item.text}</p>
                        <div className="profile_card">
                          <div className="img">
                            <img src={item.avatar} alt={item.name} />
                          </div>
                          <div className="content">
                            <p>{item.name}</p>
                            <small>{item.position}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              )}
          </SplideTrack>
          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
              <BsChevronRight />
            </button>
            <button className="splide__arrow splide__arrow--next">
              <BsChevronRight />
            </button>
          </div>
        </Splide>
      </div> */}
    </Container>
  );
}
const Container = styled.section`
  & > .title {
    width: min(777px, 100%);
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 48px;
  }
  & > .chips {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 780px) {
      grid-template-columns: repeat(2, 1fr);
    }
    & > .chip {
      padding: 24px 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      @media (max-width: 580px) {
        padding: 20px 12px;
      }
      & > .img {
        width: min(48px, 100%);
        height: min(48px, 100%);
        aspect-ratio: 1/1;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      & > .content {
        display: flex;
        flex-direction: column;
        gap: 4px;
        & > * {
          margin: 0;
        }
        & > p {
          font-size: 0.725rem;
          margin: 0;
          line-height: 100%;
        }
      }
    }
  }
  & > .content_slide {
    width: 100%;
    position: relative;
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    .card {
      display: flex;
      flex-direction: row-reverse;
      border: 1px solid var(--aj-gray-100);
      border-radius: 8px;
      overflow: hidden;
      padding: 32px;
      gap: 32px;
      width: 90%;
      margin: 0 auto;
      @media (max-width: 580px) {
        flex-direction: column;
        padding: 20px;
      }
      & > .card_media {
        flex: 1;
        width: 100%;
        display: flex;
        & > iframe {
          width: 100%;
          aspect-ratio: 16/9;
          margin: auto 0;
          border-radius: 12px;
        }
      }
      & > .card_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        gap: 16px;
        & > .ico {
          width: 68px;
          height: 68px;
          @media (max-width: 580px) {
            margin: 0 auto;
          }
          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        & > .profile_card {
          display: flex;
          gap: 16px;
          align-items: center;
          & > .img {
            width: 48px;
            height: 48px;
            & > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
          & > .content {
            & > * {
              line-height: 100%;
            }
            & > p {
              color: red;
            }
          }
        }
      }
    }
    .splide__pagination {
      gap: 2;
      bottom: -20px;
    }
    .splide__pagination__page {
      transform: scale(1);
    }
    .splide__pagination__page.is-active {
      background-color: var(--aj-primary);
      transform: scale(1.4);
    }
    .splide__arrow {
      font-size: 30px;
      background-color: #fff;
      /* border: 5px solid var(--aj-gray-100); */
      background-color: var(--aj-red-shade);
      padding: 16px;
      opacity: 1;
      @media (max-width: 580px) {
        padding: 0;
        font-size: 20px;
      }
      & > svg {
        fill: var(--aj-primary);
      }
      &--prev {
        @media (max-width: 580px) {
          left: -0.5rem;
        }
      }
      &--next {
        @media (max-width: 580px) {
          right: -0.5rem;
        }
      }
    }
  }
`;
