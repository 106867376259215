import React, { useState } from "react";
import LegendInput from "../../input/LegendInput";
import useApp from "../../../store";
import { UpdateUserData } from "../../../utils/user";

export default function StudyBudget({
  setLoading,
  setError,
  Loading,
  setStep,
  step,
}) {
  const { profileData, updateUser } = useApp();

  const [studyBudget, setStudyBudget] = useState(profileData?.studyBudget);

  function handleSubmit(e) {
    e.preventDefault();
    const uid = profileData.uid;
    const stepCompletedProfile = profileData?.stepCompletedProfile;
    const data = {
      studyBudget,
      stepCompletedProfile:
        stepCompletedProfile &&
        (isNaN(stepCompletedProfile) || stepCompletedProfile < step)
          ? step
          : stepCompletedProfile,
    };
    const changeStep = () => {
      setStep(step + 1);
      updateUser({ ...profileData, ...data });
    };
    console.log("aya");
    if (!isNaN(studyBudget)) {
      UpdateUserData(uid, data, setLoading, setError, changeStep);
    } else {
      const msg = "Enter a valid numerical value.";
      setError(msg);
    }
  }

  console.log("RAs", studyBudget);

  return (
    <form onSubmit={handleSubmit}>
      <LegendInput
        label={"Amount in USD"}
        placeholder="$000000"
        type="number"
        value={studyBudget}
        setValue={setStudyBudget}
      />
      <p style={{ textAlign: "center" }}>
        Please Consider only your tuition fee.
      </p>
      <button disabled={Loading} type="submit" className="aj-btn-primary">
        {Loading ? "Loading..." : "Next"}
      </button>
    </form>
  );
}
