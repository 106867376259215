import { FaHome, FaPassport } from "react-icons/fa";
import { GiPlatform } from "react-icons/gi";
import { TbWriting } from "react-icons/tb";

export const MentorServices = [
  {
    icon: <TbWriting />,
    title: "Essay Service",
    value: "EssayService",
    color: "--aj-orange-shade",
  },
  {
    icon: <GiPlatform />,
    title: "Full Application Mentorship",
    value: "FullApplicationMentorship",
    color: "--aj-purple-shade",
  },
  {
    icon: <FaPassport />,
    title: "Visa Guidance",
    value: "VisaGuidance",
    color: "--aj-green-shade",
  },
  {
    icon: <FaHome />,
    title: "Settle Down Guidance",
    value: "SettleDownGuidance",
    color: "--aj-blue-shade",
  },
];
