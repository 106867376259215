import React from "react";
import styled from "styled-components";

export default function SimpleLoading({ show }) {
  return (
    show && <Component className="am-loading-simple">SimpleLoading</Component>
  );
}

const Component = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--aj-light-dark-shadow);
  backdrop-filter: blur(10px);
`;
