import React, { useEffect } from "react";
import styled from "styled-components";

export default function Notification({
  show = false,
  setShow = () => {},
  duration = 3000,
  variant = "",
  children = <></>,
}) {
  useEffect(() => {
    if (show) {
      const ref = setTimeout(() => setShow(), duration);
      return clearTimeout(ref);
    }
  }, [show, duration]);

  return show && <Container className={variant}>{children}</Container>;
}

const Container = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: color-mix(in srgb, var(--aj-warning) 40%, var(--aj-white));
`;
