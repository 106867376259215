import React from "react";
import styled from "styled-components";

export default function ECAInput({ index, single, value, setValue, obj }) {
  const labelN = "Organization";
  const labelT = "Title";
  const labelD = "Describe your work";

  function handleAppliedUniversity(index, key, inp) {
    switch (key) {
      case "name":
        let arrN = [...JSON.parse(JSON.stringify(value))];
        arrN[index].name = inp;
        setValue(arrN);
        break;
      case "title":
        let arrA = [...JSON.parse(JSON.stringify(value))];
        arrA[index].title = inp;
        setValue(arrA);
        break;
      case "details":
        let arrS = [...JSON.parse(JSON.stringify(value))];
        arrS[index].details = inp?.split("\n");
        setValue(arrS);
        break;
      default:
        break;
    }
  }

  return (
    <Container>
      <InputItem>
        <label
          className="h4"
          htmlFor={labelN
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
        >
          {labelN}
        </label>
        <input
          type={"text"}
          name={labelN
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          id={labelN
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          required={true}
          placeholder={"Organization Name"}
          className={``}
          value={single?.name}
          onChange={(e) =>
            handleAppliedUniversity(index, "name", e.target.value)
          }
        />
      </InputItem>

      <InputItem>
        <label
          className="h4"
          htmlFor={labelT
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
        >
          {labelT}
        </label>
        <input
          type={"text"}
          name={labelT
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          id={labelT
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          required={true}
          placeholder={"President"}
          className={``}
          value={single?.title}
          onChange={(e) =>
            handleAppliedUniversity(index, "title", e.target.value)
          }
        />
      </InputItem>

      <InputItem>
        <label
          className="h4"
          htmlFor={labelD
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
        >
          {labelD}
        </label>
        <textarea
          name={labelD
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          id={labelD
            .split(" ")
            .map((el, ind) =>
              ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
            )
            .join("")}
          required={false}
          placeholder={`- I have 
- I have 
- I have `}
          className={``}
          value={
            single?.details &&
            Array.isArray(single.details) &&
            single.details.length > 0 &&
            single.details.join("\n")
          }
          onChange={(e) =>
            handleAppliedUniversity(index, "details", e.target.value)
          }
        ></textarea>
      </InputItem>

      <div
        className={`extra-btn delete ${
          index === value.length - 1 ? "" : "full"
        }`}
        role="button"
        onClick={() => setValue(value.filter((el, ind) => ind !== index))}
      >
        Delete {single?.name}
      </div>
      <div
        className={`extra-btn add-new ${
          index === value.length - 1 ? "" : "hide"
        }`}
        role="button"
        onClick={() => setValue([...value, obj])}
      >
        Add Another
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  @media (max-width: 670px) {
    grid-template-columns: 1fr;
  }
  & > * {
    &:nth-child(3) {
      grid-column: 1 / span 2;
      @media (max-width: 670px) {
        grid-column: 1 / span 1;
      }
    }
  }
  & > .extra-btn {
    text-align: center;
    border: 1px solid var(--aj-primary);
    border-radius: 8px;
    color: var(--aj-primary);
    padding: 8px 12px;
    &.full {
      grid-column: 1 / span 2;
      @media (max-width: 670px) {
        grid-column: 1 / span 1;
      }
    }
    &.add-new {
      &.hide {
        display: none;
      }
    }
  }
`;

const InputItem = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid var(--aj-gray-100);
  border-radius: 8px;
  margin-top: 0.5lh;
  & > label {
    position: absolute;
    left: 1rem;
    top: -0.5lh;
    background-color: var(--aj-white);
    padding: 0 0.5rem;
    font-size: 1rem;
  }
  & > input {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    color: #000;
    &:invalid,
    & > .error {
      color: var(--aj-primary);
    }
  }
  & > textarea {
    width: 100%;
    height: 120px;
    padding: 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    color: #000;
    resize: none;
    &:invalid,
    & > .error {
      color: var(--aj-primary);
    }
  }
`;
