import React, { useState } from "react";
import styled from "styled-components";
import SimpleLoading from "../../Loaders/SimpleLoading";
import { UpdateUserData } from "../../../utils/user";
import useApp from "../../../store";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

export default function NeedToWork({
  setLoading,
  setError,
  Loading,
  setStep,
  step,
}) {
  const { profileData, updateUser } = useApp();

  const [needToWork, setNeedToWork] = useState(profileData?.needToWork);

  const options = [
    {
      icon: <FaCheck />,
      title: "Yes",
      value: "Yes",
    },
    {
      icon: <ImCross />,
      title: "No",
      value: "No",
    },
  ];

  function handleClick(e, value) {
    setLoading(true);
    setNeedToWork(value);
    e.preventDefault();
    const uid = profileData.uid;
    const stepCompletedProfile = profileData?.stepCompletedProfile;
    const data = {
      needToWork: value,
      stepCompletedProfile:
        stepCompletedProfile &&
        (isNaN(stepCompletedProfile) || stepCompletedProfile < step)
          ? step
          : stepCompletedProfile,
    };
    const changeStep = () => {
      setStep(step + 1);
      updateUser({ ...profileData, ...data });
    };
    UpdateUserData(uid, data, setLoading, setError, changeStep);
  }

  return (
    <Container onSubmit={(e) => e.preventDefault()}>
      <div className="grid">
        {React.Children.toArray(
          options.map((option) => (
            <div
              className={`element ${
                needToWork === option?.value ? "selected" : ""
              }`}
              role="button"
              tabIndex={0}
              onClick={(e) => handleClick(e, option?.value)}
            >
              {typeof option?.icon === "string" ? (
                <img src={option?.icon} alt={`${option?.title} icon`} />
              ) : (
                <span className="icon">{option?.icon}</span>
              )}
              <h2 className="aj-subtitle">{option?.title}</h2>
            </div>
          ))
        )}
      </div>
      <SimpleLoading show={Loading} />
    </Container>
  );
}

const Container = styled.form`
  position: relative;
  & > .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    & > .element {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background-color: var(--aj-gray-100);
      padding: 24px;
      border-radius: 16px;
      border: 1px solid var(--aj-gray-100);
      &.selected {
        background-color: var(--aj-white);
        border-color: var(--aj-primary);
      }
      & > * {
        text-align: center;
      }
      & > .icon {
        font-size: 3rem;
      }
      & > p {
        font-weight: 500;
      }
    }
  }
`;
