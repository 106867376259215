import React from "react";
import styled from "styled-components";

export default function BrandLoading({ loading }) {
  return (
    loading && (
      <Container>
        <img src="/BrandLogo.png" alt="Brand Icon" />
        <h2>Abroad Mates</h2>
        <p>It's like having a Friend Abroad</p>
      </Container>
    )
  );
}

const Container = styled.div`
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  text-align: center;
  background-color: var(--aj-white);
  z-index: 99999;
  & > img {
    width: 150px;
    height: auto;
  }
  & > h2 {
    color: var(--aj-primary);
  }
`;
