import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { res_md } from "../../settings";
import useApp from "../../store";
import DHeader from "../../layout/Dashboard/DHeader";
import { LogUserOut } from "../../utils/user";
import { LeftSidebarOptions } from "../../data/mentorDashboard";

import { FaSignOutAlt } from "react-icons/fa";
import Dashboard from "./Dashboard";
import VerifyEmail from "../../components/blocks/VerifyEmail";
import ProfileDataUpdate from "./ProfileDataUpdate";
import { useLocation, useNavigate } from "react-router-dom";

export default function DashboardMentor() {
  const { profileData, userLogout } = useApp();

  const navigate = useNavigate();

  const [MenuShow, setMenuShow] = useState(false);
  const [ProfileShow, setProfileShow] = useState(false);

  const [Tab, setTab] = useState(
    LeftSidebarOptions &&
      Array.isArray(LeftSidebarOptions) &&
      LeftSidebarOptions.length > 0 &&
      LeftSidebarOptions[0]?.href
      ? LeftSidebarOptions[0].href
      : ""
  );

  const { search } = useLocation();
  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      if (params.get("tab")) {
        setTab(params.get("tab"));
      }
    }
  }, [search]);

  return (
    <>
      <Container>
        <DHeader
          OpenLeftMenu={MenuShow}
          setOpenLeftMenu={setMenuShow}
          OpenRightMenu={ProfileShow}
          setOpenRightMenu={setProfileShow}
        />
        <div className="aj-sd-body">
          <aside className={`${MenuShow ? "active " : ""}left`}>
            <i
              className="close"
              role="button"
              onClick={() => setMenuShow(false)}
              tabIndex={0}
            />
            {LeftSidebarOptions &&
              Array.isArray(LeftSidebarOptions) &&
              LeftSidebarOptions.length > 0 &&
              React.Children.toArray(
                LeftSidebarOptions.map((option) => (
                  <button
                    className={`aj-left-sidebar-link ${
                      Tab === option?.href ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate(`/dashboard/?tab=${option?.href}`);
                    }}
                  >
                    {option?.icon} {option?.name}
                  </button>
                ))
              )}
            <button
              className="aj-left-sidebar-link primary"
              onClick={() => LogUserOut(userLogout)}
            >
              <FaSignOutAlt />
              Sign out
            </button>
          </aside>
          <div className="aj-sd-container">
            {profileData?.emailVerified ? (
              Tab === "dashboard" ? (
                <Dashboard />
              ) : Tab === "documents" ? (
                <ProfileDataUpdate />
              ) : (
                <>
                  <h1>Coming Soon</h1>
                </>
              )
            ) : (
              <VerifyEmail />
            )}
          </div>
          <aside className={`right${ProfileShow ? " active" : ""}`}>
            <i
              className="close"
              role="button"
              onClick={() => setProfileShow(false)}
              tabIndex={0}
            />
            <h3>Your Profile:</h3>
            <div className="aj-right-profile">
              <img
                src={
                  profileData?.photoURL
                    ? profileData.photoURL
                    : "/dashboard/dummy_profile.png"
                }
                alt={
                  profileData?.displayName
                    ? profileData.displayName
                    : "User name not found"
                }
              />
            </div>
            <h4>
              {profileData?.displayName
                ? profileData.displayName
                : "User name not found"}
            </h4>
          </aside>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: min(1440px, 100%);
  margin: 0 auto;
  & > .aj-sd-body {
    width: 100%;
    display: flex;
    background-color: var(--aj-cream);
    height: calc(100dvh - 60px);
    overflow: hidden;
    & > aside {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
      background-color: var(--aj-white);
      padding: 24px 16px;
      height: 100%;
      overflow-y: auto;
      &.left {
        box-shadow: 5px 0 42px 0 var(--aj-light-dark-shadow);
        & > .aj-left-sidebar-link {
          font-size: 1rem;
          font-weight: 600;
          color: var(--aj-dark);
          width: 100%;
          text-align: left;

          display: flex;
          align-items: center;
          gap: 0.4rem;
          padding: 8px 0;
          &.active {
            color: var(--aj-purple-800);
          }
          &.primary {
            color: var(--aj-primary);
          }
        }
      }
      &.right {
        box-shadow: -5px 0 42px 0 var(--aj-light-dark-shadow);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        & > h3 {
          font-size: 1.3rem;
          width: 100%;
        }
        & > .aj-right-profile {
          width: min(100px, 100%);
          aspect-ratio: 1/1;
          border-radius: 50%;
          overflow: hidden;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        & > h4 {
          font-size: 1.1rem;
        }
      }
      & > .close {
        display: none;
      }
      @media (max-width: ${res_md}px) {
        display: none !important;
        &.active {
          position: fixed;
          left: 0;
          top: 0;
          bottom: 0;
          display: flex !important;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 80px 24px 24px;
          gap: 12px;
          width: min(80vw, 300px);
          height: 100dvh;
          backdrop-filter: blur(20px);
          -webkit-backdrop-filter: blur(20px);
          background: var(--aj-white-100);
          z-index: 99;
          &.right {
            left: auto;
            right: 0;
          }
          & > .close {
            display: flex;
            width: 20px;
            height: 2px;
            background-color: var(--aj-black-800);
            position: absolute;
            top: 32px;
            right: 24px;
            transform: rotate(45deg);
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: var(--aj-black-800);
              transform: rotate(90deg);
            }
          }
          & > button {
            width: 100%;
          }
        }
      }
    }
    & > .aj-sd-container {
      flex: 4;
      height: 100%;
      overflow-y: auto;
    }
  }
`;
