import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import useApp from "../store";

export default function Header() {
  const { loggedInStatus } = useApp();
  const [Ham, setHam] = useState(false);

  const location = useLocation();

  const NavItems = {
    left: {
      logo: "/logos/logo.svg",
      alt: "abroad mate logo",
      href: "/",
    },
    center: [
      {
        href: "/",
        text: "Home",
      },
      {
        href: "/mates",
        text: "Talk to AbroadMates",
      },
      {
        href: "https://abroadmates.substack.com",
        text: "Guide",
      },
      {
        href: "/our-story",
        text: "Our Story",
      },
    ],
    right: [
      {
        text: "Become a Mentor",
        href: "/sign-up/mentor",
        type: "secondary-outline",
      },
      { text: "Get Enrolled", href: "/sign-up", type: "primary" },
    ],
    loggedIn: [
      // {
      //   text: "Become a Mentor",
      //   href: "/sign-up/mentor",
      //   type: "secondary-outline",
      // },
      { text: "My Profile", href: "/dashboard", type: "primary" },
    ],
  };

  return (
    <Container className="aj-container_fluid">
      <div className="content">
        <Link to={NavItems.left.href} className="logo">
          <span className="logo_con">
            <img src={NavItems.left.logo} alt={NavItems.left.alt} />
          </span>
          <span className="small">alpha</span>
        </Link>

        <nav className="nav_center">
          {React.Children.toArray(
            NavItems.center.map((nav) => (
              <Link
                to={nav.href}
                className={`center_link ${
                  location.pathname === nav.href ? "active" : ""
                }`}
              >
                {nav.text}
              </Link>
            ))
          )}
        </nav>
        <nav className="nav_right">
          {React.Children.toArray(
            loggedInStatus
              ? NavItems.loggedIn.map((nav) => (
                  <Link
                    to={nav.href}
                    className={`aj-btn-${nav.type} ${
                      location.pathname === nav.href ? "active" : ""
                    }`}
                  >
                    {nav.text}
                  </Link>
                ))
              : NavItems.right.map((nav) => (
                  <Link
                    to={nav.href}
                    className={`aj-btn-${nav.type} ${
                      location.pathname === nav.href ? "active" : ""
                    }`}
                  >
                    {nav.text}
                  </Link>
                ))
          )}
        </nav>
        {Ham && (
          <div className="nav_mobile">
            <div
              className="close"
              role="button"
              onClick={() => setHam(false)}
            />
            <nav>
              {!loggedInStatus && (
                <>
                  <Link
                    to={NavItems.right[0].href}
                    className={`aj-btn-${NavItems.right[0].type} lm`}
                  >
                    {NavItems.right[0].text}
                  </Link>

                  <hr />
                </>
              )}

              {React.Children.toArray(
                NavItems.center.map((nav) => (
                  <Link
                    to={nav.href}
                    className={`center_link ${
                      location.pathname === nav.href ? "active" : ""
                    }`}
                  >
                    <span>{nav.text}</span>
                  </Link>
                ))
              )}

              <hr style={{ marginTop: "auto" }} />

              {loggedInStatus ? (
                <Link
                  to={NavItems.right[0].href}
                  className={`aj-btn-${NavItems.right[0].type} lm`}
                >
                  {NavItems.right[0].text}
                </Link>
              ) : (
                <Link
                  to={NavItems.right[1].href}
                  className={`aj-btn-${NavItems.right[1].type} lm`}
                >
                  {NavItems.right[1].text}
                </Link>
              )}
            </nav>
          </div>
        )}
        <button
          className={Ham ? "active" : ""}
          onClick={() => setHam((e) => !e)}
        >
          <span className="ico"></span>
        </button>
      </div>
    </Container>
  );
}
const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
  margin: 0 auto;
  & > .content {
    padding: 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 580px) {
      padding: 24px;
    }

    & > a {
      &.logo {
        display: flex;
        align-items: baseline;
        color: var(--aj-primary);
        text-decoration: none;
        gap: 2px;
        & > .logo_con {
          overflow: hidden;
          position: relative;
          display: block;
          width: min(96.828px, 8rem);
          aspect-ratio: 96.828/36;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        & > .small {
          font-size: 0.6rem;
          font-weight: 600;
        }
      }
    }
    & > nav {
      &.nav {
        &_center {
          display: flex;
          gap: 24px;
          & > .center_link {
            color: var(--aj-gray-400);
            padding: 9px 10px;
            text-decoration: none;
            &.active {
              color: var(--aj-primary);
            }
          }
          @media (max-width: 780px) {
            display: none;
          }
        }
        &_right {
          display: flex;
          align-items: center;
          gap: 8px;
          @media (max-width: 780px) {
            display: none;
          }
        }
      }
    }
    & > .nav_mobile {
      display: none;
      @media (max-width: 780px) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
      }
      & > .close {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      & > nav {
        width: max(300px, 70%);
        height: 100%;
        background-color: white;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 65px 30px 30px;
        & > .center_link {
          color: var(--aj-gray-400);
          text-decoration: none;
          padding: 8px 16px;
          &.active {
            color: var(--aj-primary);
            & > span {
              display: inline;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                height: 2px;
                width: 100%;
                background-color: var(--aj-primary);
                bottom: -16px;
                left: 0;
              }
            }
          }
        }
        & > .lm {
          margin-right: auto;
        }
      }
    }
    & > button {
      display: none;
      @media (max-width: 780px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 2px;
        width: 28px;
        aspect-ratio: 1/1;
        background-color: transparent;
        border: none;
        outline: none;
        & > span {
          height: 2px;
          width: 100%;
          position: relative;
          background-color: var(--aj-gray-500);
          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: -6px;
            left: 0;
            background-color: var(--aj-gray-500);
          }
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: -6px;
            left: 0;
            background-color: var(--aj-gray-500);
          }
        }
        &.active {
          & > span {
            /* opacity: 0; */
            background-color: transparent;
            &::before {
              top: 0;
              transform-origin: center;
              transform: rotate(-45deg);
            }
            &::after {
              bottom: 0;
              transform-origin: center;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
`;
