import React from "react";
import styled from "styled-components";

export default function LegendInput({
  label,
  type = "text",
  placeholder = "",
  required = true,
  value,
  setValue,
}) {
  return (
    <Container>
      <label
        className="h4"
        htmlFor={label
          .split(" ")
          .map((el, ind) =>
            ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
          )
          .join("")}
      >
        {label}
      </label>
      <input
        type={type}
        name={label
          .split(" ")
          .map((el, ind) =>
            ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
          )
          .join("")}
        id={label
          .split(" ")
          .map((el, ind) =>
            ind === 0 ? el?.[0].toLowerCase() : el?.[0].toUpperCase()
          )
          .join("")}
        required={required}
        placeholder={placeholder}
        className={``}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid var(--aj-gray-100);
  border-radius: 8px;
  margin-top: 0.5lh;
  & > label {
    position: absolute;
    left: 1rem;
    top: -0.5lh;
    background-color: var(--aj-white);
    padding: 0 0.5rem;
    font-size: 1rem;
  }
  & > input {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    color: #000;
    &:invalid,
    & > .error {
      color: var(--aj-primary);
    }
  }
`;
